import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {StorageDataKey, StorageService} from "../service/storage.service";
import {UserModelData} from "@prestinly/core-lib/lib/types/users";

export const isCreatorGuard: CanActivateFn = (route, state) => {
  const user = inject(StorageService).getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
  const isCreator = user != null && user.isCreator;
  if (!isCreator) {
    inject(Router).navigateByUrl('/my-profile');
  }
  return isCreator;
};

export const canBecomeCreatorGuard: CanActivateFn = (route, state) => {
  const user = inject(StorageService).getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
  const isCreator = user != null && user.isCreator;
  if (isCreator) {
    inject(Router).navigateByUrl('/my-profile');
  }
  return !isCreator;
};

export const canSeeMyProfile: CanActivateFn = (route, state) => {
  const user = inject(StorageService).getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
  return user != null && user.isCreator;
};
